import React from "react"
import style from "./workout.mod.scss"
import Sweatometer from "./Sweatometer"

const SweatometerComponent = ({ workoutID, defaultRating, userID }) => {
  return (
    <div className={style.workout__sweatometer}>
      <Sweatometer
        workoutID={workoutID}
        userID={userID}
        defaultRating={defaultRating}
      />
    </div>
  )
}
export default SweatometerComponent
