import React from "react"
import style from "./surface.mod.scss"
import { graphql } from "gatsby"
import PropTypes from "prop-types"

const SurfaceHeading = ({ headingLevel, user, heading }) => {
  switch (headingLevel) {
    case "1":
    default:
      return (
        <h1 className={style.surface__heading}>
          {heading}
          <br />
          {user}
        </h1>
      )
  }
}

const SurfaceLead = ({ lead }) => {
  return (
    <div
      className={style.surface__lead}
      dangerouslySetInnerHTML={{ __html: lead }}
    />
  )
}

const Surface = ({
  children,
  heading,
  lead,
  user,
  headingLevel = "1",
  elevated = false,
  modifiers = [],
  padding = "none",
  background = "none",
  margin = "none",
  maxWidth = "auto",
  fontSize = "sm"
}) => {
  elevated && modifiers.push(style[`surface--elevated`])
  padding && modifiers.push(style[`surface--padding-${padding}`])
  margin && modifiers.push(style[`surface--margin-${margin}`])
  maxWidth && modifiers.push(style[`surface--max-width-${maxWidth}`])
  fontSize && modifiers.push(style[`surface--font-size-${fontSize}`])

  return (
    <div className={[style.surface, ...modifiers].join(" ")}>
      {heading && (
        <SurfaceHeading
          headingLevel={headingLevel}
          user={user}
          heading={heading}
        />
      )}
      {lead && <SurfaceLead lead={lead} />}
      <div className={style.surface__body} children={children} />
    </div>
  )
}

export default Surface

Surface.propTypes = {
  children: PropTypes.node.isRequired,
  heading: PropTypes.string,
  headingLevel: PropTypes.string,
  elevated: PropTypes.bool,
  modifiers: PropTypes.array,
  padding: PropTypes.string
}

export const query = graphql`
  fragment TourStep on DatoCmsTourStep {
    id
    heading
    topText
    bottomText
    media {
      gatsbyImageData(imgixParams: { fit: "crop", w: "770", h: "433" })
    }
  }
  fragment TourStepVideo on DatoCmsTourStepVideo {
    id
    heading
    topText
    bottomText
    video {
      url
      providerUid
    }
  }
`
