import React from "react"
import { useAuth0 } from "@utils/auth"
import CompletedButton from "@global/cards/CompletedButton"

const WorkoutCompleted = ({ id }) => {
  const {
    addCompletedWorkouts,
    removeCompletedWorkouts,
    completedWorkouts
  } = useAuth0()

  const handleAddFavourite = id => {
    addCompletedWorkouts(id)
  }

  const handleRemoveFavourite = id => {
    removeCompletedWorkouts(id)
  }

  return (
    <>
      <CompletedButton
        id={id}
        onAddFavourite={handleAddFavourite}
        onRemoveFavourite={handleRemoveFavourite}
        favouriteArray={completedWorkouts}
      />
    </>
  )
}

export default WorkoutCompleted
