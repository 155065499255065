import React from "react"
import style from "./workout.mod.scss"
import { GatsbyImage } from "gatsby-plugin-image"
import Vimeo from "@elements/Vimeo"
import { getImageFromMedia } from "@helpers/workoutHelper"

const Banner = ({ media, type }) => {
  return (
    <div className={style.workout}>
      <div className={style.workout__hero}>
        {type === "video" ? (
          <WorkoutVideo media={media} />
        ) : (
          <WorkoutAudio media={media} />
        )}
      </div>
    </div>
  )
}

export default Banner

const WorkoutVideo = ({ media }) => {
  const poster = getImageFromMedia(media)
  return (
    <>
      <Vimeo
        videoUrl={media.url}
        fileUrl={media.fileUrl}
        timestamp={media.timestamp}
        poster={poster}
      />
    </>
  )
}

const WorkoutAudio = ({ media }) => {
  return (
    <>
      <GatsbyImage
        className={style.workout__image}
        image={
          media.chosenThumbnail
            ? media.chosenThumbnail.gatsbyImageData
            : media.banner.gatsbyImageData
        }
      />
      <div className={style.workout__audioWrap}>
        <div className={style.workout__playerWrap}>
          <audio
            className={style.workout__audio}
            controls
            controlsList="nodownload"
            src={media.audioFile.url}
          >
            <track kind="Workouts" />
          </audio>
        </div>
        <p className={style.workout__caption}>{media.caption}</p>
      </div>
    </>
  )
}
