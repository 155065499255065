import React, { useEffect, useState } from "react"
import Button from "@elements/Button"

const CompletedButton = ({
  id,
  onRemoveFavourite,
  onAddFavourite,
  favouriteArray = []
}) => {
  const [selected, setSelected] = useState(false)

  useEffect(() => {
    const checkIsFavourite = favouriteArray.includes(id)
    checkIsFavourite ? setSelected(true) : setSelected(false)
  }, [])

  const handleFavourites = id => {
    if (!selected) {
      onAddFavourite(id)
      setSelected(!selected)
    } else {
      onRemoveFavourite(id)
      setSelected(!selected)
    }
  }

  const text = selected ? (
    "Completed"
  ) : (
    <span>
      Complete<span> workout</span>
    </span>
  )

  return (
    <Button
      type="button"
      completed={selected ? true : false}
      text={text}
      onClick={() => handleFavourites(id)}
    />
  )
}

export default CompletedButton
