import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { updateRating, roundRating } from "@helpers/sweatometerHelper"
import style from "./sweatometer.mod.scss"
import Drop from "@svgs/drop.svg"
import firebase from "gatsby-plugin-firebase"
import { useObjectVal } from "react-firebase-hooks/database"

const options = [1, 2, 3, 4, 5]
const minValue = options[0]
const maxValue = options.slice(-1)[0]

const Sweatometer = ({ workoutID, userID, defaultRating = 2.5 }) => {
  const data = useStaticQuery(graphql`
    {
      content: contentYaml(language: { eq: "en-NZ" }) {
        sweatometer {
          description
          maxLabel
          minLabel
          ratedDescription
          ratedTitle
          title
          heading
        }
      }
    }
  `)
  const modifier = []
  const workoutPath = `workouts/${workoutID}`
  const userPath = `users/${userID}/ratings/${workoutID}`
  const db = firebase.database()

  const [workout, workoutIsLoading] = useObjectVal(db.ref(workoutPath))
  const [userRating, userRatingIsLoading] = useObjectVal(db.ref(userPath))

  if (workout === null) {
    db.ref(workoutPath).set({
      rating: defaultRating,
      count: 0,
      ratings: {}
    })
  }

  const handleOnChange = ({ target }) => {
    const userRatingRef = db.ref(userPath)
    const workoutRef = db.ref(workoutPath)
    const newRating = parseInt(target.value, 10)

    updateRating(workoutRef, userRatingRef, userID, newRating)
  }

  const {
    description,
    maxLabel,
    minLabel,
    ratedDescription,
    ratedTitle,
    title,
    heading
  } = data.content.sweatometer

  const SweatometerRating = () => {
    const rating =
      workout && workout.rating !== 0 ? workout.rating : defaultRating

    return (
      <text
        className={style.sweatometer__average}
        transform="translate(0 2)"
        x="61.5"
        y="41"
        children={`${roundRating(rating)}/${maxValue}`}
      ></text>
    )
  }

  const SweatometerCount = () => {
    const count = workout.count
    const countText =
      count > 0 ? `${count} Rating${count > 1 ? "s" : ""}` : `No rating yet`
    return <div className={style.sweatometer__count}>{countText}</div>
  }

  return (
    <div className={[style.sweatometer, ...modifier].join(" ")}>
      <h3>{heading}</h3>
      <div className={style.sweatometer__wrapper}>
        <div className={style.sweatometer__left}>
          <div className={style.sweatometer__rating}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 125 64">
              <g fill="none" fillRule="evenodd">
                <path
                  d="M78.4 55.2c-8.8 9-23 9-31.8 0A23.4 23.4 0 0140 39c0-10 10.7-22.8 18.5-32l4-4.9 4 4.9c7.8 9.2 18.5 22 18.5 32 0 6.1-2.4 12-6.6 16.3z"
                  className={style.sweatometer__drop}
                />
                {!workoutIsLoading && <SweatometerRating />}
              </g>
            </svg>
          </div>
          {!workoutIsLoading && <SweatometerCount />}
        </div>
        {!userRatingIsLoading && (
          <form className={style.sweatometer__form}>
            <h6>{userRating ? ratedTitle : title}</h6>
            <p>{userRating ? ratedDescription : description}</p>
            {options.map(option => {
              const showHighlighted = userRating && userRating.rating >= option
              return (
                <label
                  key={`rating-label-${option}`}
                  className={style.sweatometer__label}
                >
                  <input
                    className={style.sweatometer__input}
                    name="rating"
                    onChange={e => handleOnChange(e)}
                    data-is-active={showHighlighted}
                    id={`rating-input-${option}`}
                    type="radio"
                    value={option}
                    checked={
                      userRating && userRating.rating === option ? true : false
                    }
                  />
                  <span>{option}</span>
                  <Drop />
                </label>
              )
            })}
            <div className={style.sweatometer__key}>
              <label
                className={style.sweatometer__min}
                htmlFor={`rating-input-${minValue}`}
              >
                {minLabel}
              </label>

              <label
                className={style.sweatometer__max}
                htmlFor={`rating-input-${maxValue}`}
              >
                {maxLabel}
              </label>
            </div>
          </form>
        )}
      </div>
    </div>
  )
}

export default Sweatometer

export const query = graphql`
  fragment Sweatometer on DatoCmsWorkout {
    showSweatometer
    defaultRating
  }
`
/**
 * Notes:
 *
 * 1. Save Rating to RT database
 * 2. Fetch All ratings for workout with ID#
 * 3. Get user Rating
 */
