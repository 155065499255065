import React, { useState } from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import Layout from "@layouts/layout"
import Block from "@global/layout/Block"
import { HelmetDatoCms } from "gatsby-source-datocms"
import { ProtectedRoute } from "../components/ProtectedRoute"
import { useAuth0 } from "@utils/auth"
import ModuleArea from "@helpers/moduleArea"
import * as FirebaseComments from "../components/Comments"
import * as Workout from "../components/workout"
import Spinner from "@global/layout/Spinner"

const permissions = `${process.env.GATSBY_AUTH0_NAMESPACE}permissions`

const WorkoutDetail = ({
  data: { workout },
  pageContext: { parent, language }
}) => {
  const { loading, user } = useAuth0()
  const [isModalOpen, setModalOpen] = useState(false)
  const setModal = boolean => setModalOpen(boolean)

  const customer = user?.[permissions].customer

  const getType = () => {
    if (!workout.media || workout.media.length < 1) {
      console.log("No media found on workout")
      return false
    }

    switch (workout.media[0].model.apiKey) {
      case "workout_video_native":
        return "video_native"
      case "workout_video":
        return "video"
      default:
        return "audio"
    }
  }
  const type = getType()

  const {
    id,
    originalId,
    title,
    defaultRating,
    category,
    duration,
    media,
    content,
    meta,
    showSweatometer = true,
    showComments = true,
    instructor
  } = workout

  return (
    <ProtectedRoute>
      <Layout parent={parent}>
        <HelmetDatoCms defer={false} seo={workout.seoMetaTags} />
        <WorkoutWrapper>
          <Workout.Banner type={type} media={media[0]} />
          <Workout.Info
            workout={workout}
            defaultRating={defaultRating}
            instructor={instructor}
            isModalOpen={isModalOpen}
            showSweatometer={showSweatometer}
            category={category}
            title={title}
            originalId={originalId}
            setModal={setModal}
            setModalOpen={setModalOpen}
            duration={duration}
            meta={meta}
            user={user}
          />

          {showSweatometer && (
            <Block padding="top" gutters={true} maxWidth="inner">
              <Workout.SweatometerComponent
                user={user?.name}
                defaultRating={defaultRating}
                workoutID={id}
                userID={user?.sub}
              />
            </Block>
          )}

          {/* Equipment and Self Led Version */}
          {content && <ModuleArea modules={content} />}
          {/* Equipment and Self Led Version */}

          {showComments && (
            <Workout.CommentsWrapper>
              <FirebaseComments.List id={originalId} />
              <FirebaseComments.SubmitHeader
                text="Submit a Comment"
                loggedAs={
                  customer?.first_name && customer?.last_name
                    ? `${customer.first_name} ${customer.last_name[0]}`
                    : customer?.email
                }
              />
              <FirebaseComments.SubmitForm id={id} originalId={originalId} />
            </Workout.CommentsWrapper>
          )}
        </WorkoutWrapper>
      </Layout>
    </ProtectedRoute>
  )
}

export const WorkoutWrapper = ({ children }) => {
  return <>{children}</>
}

export const query = graphql`
  query workoutById($id: String) {
    workout: datoCmsWorkout(id: { eq: $id }) {
      ...Workout
    }
  }
`

export default WorkoutDetail

WorkoutDetail.propTypes = {
  workout: PropTypes.shape({
    id: PropTypes.string.isRequired,
    originalId: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    defaultRating: PropTypes.number.isRequired,
    category: PropTypes.shape({
      title: PropTypes.string,
      slug: PropTypes.string,
      id: PropTypes.string
    }).isRequired,
    duration: PropTypes.number.isRequired,
    media: PropTypes.array.isRequired,
    content: PropTypes.array,
    meta: PropTypes.shape({
      publishedAt: PropTypes.string
    }).isRequired
  }),
  type: PropTypes.string
}
