import React from "react"
import { useAuth0 } from "@utils/auth"
import FavouriteHeartButton from "@global/cards/FavouriteHeartButton"
import { useFavourite } from "../../context/FavouritesContext/FavouriteState"

const WorkoutFavourite = ({ id, workout }) => {
  const { workouts, addWorkout, removeWorkout } = useFavourite()
  const {
    addFavouriteWorkouts,
    favouriteWorkouts,
    removeFavouriteWorkouts
  } = useAuth0()

  const handleAddFavourite = id => {
    addWorkout(workout)
    addFavouriteWorkouts(id)
  }

  const handleRemoveFavourite = id => {
    removeWorkout(workout)
    removeFavouriteWorkouts(id)
  }

  return (
    <>
      <FavouriteHeartButton
        id={id}
        onAddFavourite={handleAddFavourite}
        onRemoveFavourite={handleRemoveFavourite}
        favouriteArray={favouriteWorkouts}
        large={true}
      />
    </>
  )
}

export default WorkoutFavourite
