import React from "react"
import style from "./workout.mod.scss"
import { formatDate } from "@helpers/dateHelper"
import WorkoutFavourite from "./Favourite"
import WorkoutCompleted from "./Completed"
import Icon from "@elements/Icon"
import Block from "@global/layout/Block"
import Button from "@elements/Button"
import Instructor from "@elements/Instructor"
import Modal from "@elements/Modal"
import Surface from "@elements/Surface"

const Info = ({
  instructor,
  isModalOpen,
  category,
  title,
  originalId,
  setModal,
  setModalOpen,
  duration,
  meta,
  workout
}) => {
  return (
    <Block padding="top" gutters={true} maxWidth="inner">
      <div className={style.workout__heading_area}>
        <div className={style.workout__headings}>
          <div className={style.workout__top_row}>
            <p className={style.workout__category}>{category.title}</p>
          </div>
          <div className={style.workout__bottom_row}>
            <h1 className={style.workout__heading}>{title}</h1>
            <WorkoutFavourite workout={workout} id={originalId} />
          </div>
        </div>
      </div>
      <div className={style.workout__meta_area}>
        <div className={style.workout__meta}>
          {meta && (
            <p className={style.workout__date}>
              {formatDate(meta.publishedAt)}
            </p>
          )}
          <Icon type="cook" content={`${duration} min`} />
        </div>
        <div className={style.workout__buttons}>
          <WorkoutCompleted id={originalId} />
          {instructor && (
            <div className={style.workout__instructor}>
              <Button
                text={`About trainer`}
                onClick={e => {
                  e.preventDefault()
                  setModal(true)
                }}
              />
              <Modal
                globalState={isModalOpen}
                onClose={setModalOpen}
                overflowScroll
                noPadding
                flexInner
                closeOnWhite
                insetClose
              >
                <Surface maxWidth="card" margin="auto">
                  <Instructor {...instructor} />
                </Surface>
              </Modal>
            </div>
          )}
        </div>
      </div>
    </Block>
  )
}

export default Info
