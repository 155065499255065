import React from "react"
import style from "./instructor.mod.scss"
import { GatsbyImage } from "gatsby-plugin-image"

const Instructor = ({
  image,
  firstName,
  lastName,
  role,
  training,
  excerpt,
  flagImage,
  flagImage2,
  logoImage
}) => {
  return (
    <div className={style.instructor}>
      {image && (
        <GatsbyImage
          className={style.instructor__image}
          image={image.gatsbyImageData}
        />
      )}
      {firstName && (
        <div className={style.instructor__name}>
          <h3>{`${firstName} ${lastName}`}</h3>
          {flagImage && <GatsbyImage image={flagImage.gatsbyImageData} />}
          {flagImage2 && <GatsbyImage image={flagImage2.gatsbyImageData} />}
        </div>
      )}
      {role && <p className={style.instructor__role}>{role}</p>}
      {training && <p className={style.instructor__training}>{training}</p>}
      {excerpt && <p className={style.instructor__excerpt}>{excerpt}</p>}
      {logoImage && (
        <GatsbyImage
          className={style.instructor__logo}
          image={logoImage.gatsbyImageData}
        />
      )}
    </div>
  )
}

export default Instructor
